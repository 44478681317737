import { h } from 'vue'
import moment from 'moment'
const useFormatDate = (format: string) => {
   return ({ text }: {
       text: number | string;
   }) => {
       return h('span', text ? moment(text).format(format) : '')
   }
}
export default useFormatDate
  