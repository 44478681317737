
import { computed, defineComponent, ref, toRaw } from "vue";
import {
  FileDoneOutlined,
  FileSearchOutlined,
  FileTextOutlined,
} from "@ant-design/icons-vue";
import TableWrapper from "@/views/Payment/components/TableWrapper.vue";
import {
  getPackageTrackingDealerList,
  fetchDealerLog,
} from "@/API/checking/spotChecking";
import useFormatDate from "@/utils/payment/useFormatDate";
import LogModal from "../../spotCheckConfirm/spotCheckConfirmIndex/logModal.vue";
import downloadFile from "@/utils/payment/downloadFile";
import { message } from "ant-design-vue";
import { toThousandsAndTwoDecimal } from "@/utils/payment/numberFormat";
import { useStore } from "vuex";
import { useRouter } from 'vue-router'; 
export default defineComponent({
  name: "App",
  props: {
    query: {
      type: Object,
    },
  },
  setup(props) {
    const column = ref([
      {
        title: "Package Code",
        width: 180, dataIndex: "packageCode",
        slots: { customRender: 'packageCode'} },
      {
        title: "Submit Program No. / Amount",
        width: 220,
        slots: { customRender: "submitProgramNo" },
      },
      {
        title: "Spot Checked No. / Amount ",
        width: 200,
        slots: { customRender: "spotCheckedNo" },
      },
      {
        title: "Controlling Check No. / Amount ",
        width: 240,
        slots: { customRender: "controllingCheckedNo" },
      },
      {
        title: "Time Line From",
        dataIndex: "timeLineFrom",
        customRender: useFormatDate("YYYY-MM-DD"),
        align: "left",
        width:150
      },
      {
        title: "To",
        dataIndex: "timeLineTo",
        customRender: useFormatDate("YYYY-MM-DD"),
        align: "left",
        width:100
      },
      { title: "Priority", dataIndex: "priority"  ,width: 60,},
      {
        title: "Operation",
        slots: { customRender: "operation" },
        width: 100,
        fixed: "right",
      },
      {
        title: "Controlling Confirm",
        width: 180,
        dataIndex: "controllingConfirm",
        fixed: "right",
      },
      {
        title: "Log",
        width: 60,
        slots: { customRender: "log" },
        fixed: "right",
      },
    ]);
    const dataRes = ref();
    const data = computed(() => {
      return dataRes.value;
    });
    const search = () => {
      getPackageTrackingDealerList(props.query).then((res) => {
        console.log(res);
        dataRes.value = res;
      });
    };
    const { commit, state } = useStore();
    const router = useRouter();
    const handleExport = (record: any) => {
      const params = {
        url: `/claimapi/SpotChecking/excelByPackageCodeId?packageCodeId=${record.id}&programBuId=${state.user.bu.orgid}`,
        method: "get",
      };
      downloadFile(params).catch((res) => {
        if (res.message) {
          message.warning(res.message);
        } else {
          message.warning(res);
        }
      });
    };
    const handleExportSampleCheckForm = (record: any) => {
      const params = {
        url: `/claimapi/packageTracking/dealer/export/${record.id}`,
        method: "post",
      };
      downloadFile(params).catch((res) => {
        if (res.message) {
          message.warning(res.message);
        } else {
          message.warning(res);
        }
      });
    };
    const logModalVisible = ref(false);
    const logData = ref([]);
    const handleLog = (record: any) => {
      fetchDealerLog(record.id).then((res) => {
        logData.value = res;
        logModalVisible.value = true;
      });
    };
    const handleGoTo = (record: any) => {
      commit("spotChecking/updateSpotCheckConfirmPackageDealer", {
        ...record,
        type: "overview",
      });
      router.push("/spotCheckConfirmProgramDealer");
    };
    return {
      column,
      data,
      search,
      isPagination: false,
      handleExport,
      handleExportSampleCheckForm,
      handleLog,
      logModalVisible,
      logData,
      toThousandsAndTwoDecimal,
      handleGoTo
    };
  },
  components: {
    // FileDoneOutlined,
    FileSearchOutlined,
    TableWrapper,
    LogModal,
    FileTextOutlined,
  },
});
