
import { defineComponent, PropType } from "vue";
import { useStore } from "vuex";
import useModel from "@/hooks/useModel";
export default defineComponent({
  props: {
    isDefault: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String as PropType<any>,
    },
  },
  setup(props) {
    const mockData = [
      // {
      //   entityName: "BBAC",
      // },
      {
        entityName: "FBAC",
      },
      {
        entityName: "MBCL",
      },
      {
        entityName: "DENZA",
      },
    ];

    const { state } = useStore();
    const _value = useModel(props, "value");
    const entityList = state.user.entityList.length ? state.user.entityList : [
      {
        entityName: "BBAC",
      },
      {
        entityName: "FBAC",
      },
      {
        entityName: "MBCL",
      },
      {
        entityName: "DENZA",
      },
    ]
    // const entityList = mockData;
    const setDefaultValue = () => {
      if (props.isDefault && !_value.value) {
        if (entityList.length > 0) {
          _value.value = entityList[0].entityName;
        } else {
          _value.value = ''
        }
      }
    };
    const reset = () => {
       if (props.isDefault) {
          if (entityList.length > 0) {
          _value.value = entityList[0].entityName;
        } else {
          _value.value = ''
        }
       } 
    };
    setDefaultValue();
    return {
      entityList,
      _value,
      reset
    };
  },
});
