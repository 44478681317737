
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  computed,
  onMounted,
} from "vue";
import moment from "moment";
import BuSelector from "@/views/Payment/components/BuSelector.vue";
import EntitySelector from "@/views/Payment/components/EntitySelector.vue";
import DealerTable from "./dealerTable.vue";
import FlTable from "./flTable.vue";
export default defineComponent({
  name: "App",
  props: {},
  setup() {
    const dealerRef = ref();
    const flRef = ref();
    const query = reactive({
      entity: undefined,
      bu: undefined,
      priority: undefined,
      submissionPeriodEnd: "",
      submissionPeriodStart: "",
    });
    const period = ref<any[]>([]);
    const initPeriod = () => {
      query.submissionPeriodStart = moment()
        .month(moment().month() - 1)
        .startOf("month")
        .format("YYYY-MM-DD");
      query.submissionPeriodEnd = moment()
        .month(moment().month() + 1)
        .endOf("month")
        .format("YYYY-MM-DD");
      period.value = [query.submissionPeriodStart, query.submissionPeriodEnd];
    };
    const handlePeriodChange = (period: any) => {
      query.submissionPeriodStart = moment(period[0]).format("YYYY-MM-DD");
      query.submissionPeriodEnd = moment(period[1]).format("YYYY-MM-DD");
    };
    const init = () => {
      initPeriod();
      dealerRef.value.search();
      flRef.value.search();
    };
    onMounted(() => {
      init();
    });
    const search = () => {
      // initPeriod();
      dealerRef.value.search();
      flRef.value.search();
    };
    const reset = () => {
      initPeriod()
      query.entity = undefined;
      query.bu = undefined;
      query.priority = undefined;
      dealerRef.value.search();
      flRef.value.search();
    };
    return {
      search,
      reset,
      dealerRef,
      flRef,
      period,
      handlePeriodChange,
      priorityList: [
        {
          name: "High",
          value: "1",
        },
        {
          name: "Normal",
          value: "2",
        },
      ],
      query,
      ...toRefs(query),
    };
  },
  components: {
    BuSelector,
    EntitySelector,
    DealerTable,
    FlTable,
  },
});
