
import { defineComponent, ref, computed } from "vue";
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import {
  FileDoneOutlined,
  FileSearchOutlined,
  FileTextOutlined,
} from "@ant-design/icons-vue";
import TableWrapper from "@/views/Payment/components/TableWrapper.vue";
import useFormatDate from "@/utils/payment/useFormatDate";
import { getPackageTrackingFlList } from "@/API/checking/spotChecking";
import { toThousandsAndTwoDecimal } from "@/utils/payment/numberFormat";
import { message } from "ant-design-vue";
import downloadFile from "@/utils/payment/downloadFile";
import LogModal from "../../spotCheckConfirm/spotCheckConfirmIndex/logModal.vue";
import {
  getFinancialList,
  getFinancialLoglist,
} from "@/API/checking/spotChecking";
export default defineComponent({
  name: "App",
  props: {
    query: {
      type: Object,
    },
  },
  setup(props) {
    const { commit } = useStore();
    const router = useRouter();
    const column = ref([
      { title: "Payout Code",
        width: 220,
        slots: { customRender: "payoutCode" }
      },
      {
        title: "Total VIN No. / Amount",
        width: 180,
        slots: { customRender: "totalNo" },
      },
      {
        title: "Spot Checked Passed VIN No. / Amount ",
        width: 300,
        slots: { customRender: "spotCheckedNo" },
      },
      {
        title: "Controlling Check Passed VIN No. / Amount ",
        width: 320,
        slots: { customRender: "controllingCheckedNo" },
      },
      {
        title: "Time Line From",
        dataIndex: "timeLineFrom",
        customRender: useFormatDate("YYYY-MM-DD"),
        align: "left",
      },
      {
        title: "To",
        dataIndex: "timeLineTo",
        customRender: useFormatDate("YYYY-MM-DD"),
        align: "left",
        width:120
      },
      { title: "Priority", dataIndex: "priority" ,width:80},
      {
        title: "Operation",
        slots: { customRender: "operation" },
        width: 100,
        fixed: "right",
      },
      {
        title: "Controlling Confirm",
        width: 180,
        dataIndex: "controllingConfirm",
        fixed: "right",
      },
      {
        title: "Log",
        width: 60,
        slots: { customRender: "log" },
        fixed: "right",
      },
    ]);
    const dataRes = ref();
    const data = computed(() => {
      return dataRes.value;
    });
    const search = () => {
      getPackageTrackingFlList(props.query).then((res) => {
        dataRes.value = res;
      });
    };
    const handleExport = (record: any) => {
      const params = {
        // url: `/claimapi/packageTracking/finance/export?payoutCodeId=${record.id}`,
        url: `/claimapi/packageTracking/finance/export/${record.id}`,
        method: "post",
      };
      downloadFile(params).catch((res) => {
        if (res.message) {
          message.warning(res.message);
        } else {
          message.warning(res);
        }
      });
    };
    const logModalVisible = ref(false);
    const logData = ref([]);
    const handleLog = (record: any) => {
      logModalVisible.value = true;
      getFinancialLoglist(record.id).then((res: any) => {
        logData.value = res.data;
        logModalVisible.value = true;
      });
    };
    const handleGoto = (record: any) => {
      commit("spotChecking/updateSpotCheckConfirmPackageFl", {
        ...record,
        type: 'overview'
      });
      router.push({ path: "/spotCheckConfirmProgramFl" });
    };
    return {
      column,
      data,
      search,
      isPagination: false,
      toThousandsAndTwoDecimal,
      handleExport,
      handleLog,
      logModalVisible,
      logData,
      handleGoto
    };
  },
  components: {
    TableWrapper,
    FileTextOutlined,
    FileSearchOutlined,
    LogModal,
  },
});
