
import { defineComponent, computed } from "vue";
import useModel from "@/hooks/useModel";
export default defineComponent({
  name: "App",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    logData: {
      required: true,
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const _visible = useModel(props, "visible");
    const logColumns = computed(() => {
      return [
        {
          title: "Time",
          dataIndex: "creatAt",
          slots: { customRender: "creatAt" },
        },
        { title: "User", dataIndex: "opUsername" },
        { title: "Operation", dataIndex: "opValue" },
      ];
    });
    const handleCancel = () => {
      _visible.value = false;
    };
    return {
      _visible,
      handleCancel,
      logColumns,
    };
  },
});
